import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';

const RootLayout = lazy(() => import('pages/layout/Layout'));
const LoginPage = lazy(() => import('pages/login/Login'));
const AuctionSchedulePage = lazy(() => import('pages/auction/page'));
const AuctionDetailPage = lazy(() => import('pages/auction/[id]/page'));
const HistoryPage = lazy(() => import('pages/history/page'));
const HistoryDetailPage = lazy(() => import('pages/history/[id]/page'));
const SentryTesPage = lazy(() => import('pages/sentry/SentryTes'));

const Router = () => {
  return (
    <Routes>
      <Route>
        <Route element={<SentryTesPage />} path="/page-sentry-tes"/>
        <Route element={<AuthRoute />} path="login">
          <Route index element={<LoginPage />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<RootLayout />}>
            <Route element={<Navigate replace to="/auction" />} index />
            <Route element={<AuctionSchedulePage />} path="auction" />
            <Route element={<AuctionDetailPage />} path="auction/:id" />
            <Route element={<HistoryPage />} path="history" />
            <Route element={<HistoryDetailPage />} path="history/:id" />
            <Route element={<p>page not found</p>} path="*" />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
