import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import useAppState from 'context/useAppState';
import Page from 'routes/index';
import Loading from 'components/Loading';
import useApp from './useApp';
import * as Sentry from "@sentry/react";

function App() {
  const { pathname } = useLocation();

  const { isOpen } = useAppState((state) => state.loading);

  const { checkCredential, scrollToTop, loading } = useApp();

  const { toasts } = useToasterStore()

  const TOAST_LIMIT = 3
  // Enforce Limit
  useEffect(() => {
    toasts
      .filter(t => t.visible) // Only consider visible toasts
      .filter((item, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach(t => toast.dismiss(t.id)) // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts])

  useEffect(() => {
    checkCredential();
    scrollToTop();
  }, [pathname, checkCredential, scrollToTop]);

  if (loading)
    return <div className="fixed inset-0 h-screen w-full bg-black/50">loading credential...</div>;

  return (
    <>
      <Page />
      <Toaster />
      {!isOpen ? <></> : <Loading />}
    </>
  );
}

export default Sentry.withProfiler(App);
